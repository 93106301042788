<template>
    <div>
        <content-subheading :heading="'Call ' + $config.public.companyTelephone" marginBottom="mb-6"></content-subheading>
        <p>
            <image-general
                :src="$config.public.images + 'ageuk.jpg'"
                imageAspectRatio="aspect-[125/77]"
            ></image-general>
        </p>
        <p class="text-zinc-200">Age UK Registered Member</p>
        <p>
            You can read or leave reviews on the quality of our service on the
            <a
                href="https://www.aubdcalderdaleandkirklees.co.uk/Result/88d0bbca-dbee-4b8e-a967-cceacca7dfba/The+Real+Lock+Company"
                target="_blank"
                rel="noopener noreferrer nofollow"
            >
                Age UK Business Directory Web Site
            </a>.
        </p>
        <p>
            "Unless you have recently changed your Europrofile Locking Barrels to the more secure ones such
            as, ‘Break Secure and Snap Safe’, it is likely that those fitted at your home are at risk."
        </p>
        <p><strong>West Yorkshire Police</strong></p>
        <content-subheading heading="Snapping" marginBottom="mb-6"></content-subheading>
        <embed-youtube id="yFRRQndz5Sw"></embed-youtube>
        <p>
            Snapping or the ‘Snap Attack’ is the method employed by burglars to snap off the front part of the lock
            cylinder, to expose the internal parts of the locking mechanism.
        </p>
        <p>
            The method exploits a known vulnerability in euro style door locks and a burglar can easily bypass these
            locks in under 20 seconds with every day tools.
        </p>
        <content-subheading heading="Bumping" marginBottom="mb-6"></content-subheading>
        <p>
            Bumping uses a special key, which is now widely available on the internet to simply unlock your door. What’s
            worse, Bumping involves no forced entry and does not even damage the lock – NO FORCED ENTRY = NO
            INSURANCE!
        </p>
        <p>
            Many of our clients are stunned to learn, that the locks in their doors were barely a minor inconvenience to
            the opportunist thief. We aim to put your mind at rest and keep you safe.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleHomeTextRight',
};
</script>
