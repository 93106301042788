<template>
    <div>
        <content-subheading heading="IS YOUR HOME SECURE?" marginBottom="mb-6"></content-subheading>
        <p>
            If your home has Europrofile Locking Cylinder door locks – which are fitted to 90% of UPVC and composite
            doors in the UK – then a burglar can enter your house in seconds and your home is currently at risk. The
            locks can be ‘Bumped’ or ‘Snapped’ in a matter of seconds, with little noise.
        </p>
        <p>
            The number of burglaries in Kirklees and the surrounding areas, where the offender has used these methods to
            gain entry is rising fast.
        </p>
        <p>
            Even more worryingly, now the average burglar has easy access to these techniques via the internet. In recent
            years, Snapping and Bumping have reached epidemic proportions in West Yorkshire and burglars are getting
            wise to these techniques across the UK.
        </p>
        <p>
            "Burglars have discovered what the lock industry have known for years, the weak point of your door
            is the lock cylinder – it doesn’t matter how many dead bolts you have these can easily be bypassed."
        </p>
        <content-subheading heading="So What’s the Real Solution?" marginBottom="mb-6"></content-subheading>
        <p>
            We at The Real Lock Company will supply and fit you a cylinder lock that is truly snap secure, anti bump and
            anti pick. The lock that has been awarded The Diamond Standard PAS 024 Secured By Design accreditation and
            has also gained 3 STARS after being tested to the new TS007 Standard.
        </p>
        <p>
            We provide a free assessment of your current locks… we will not fit new locks when not needed!
        </p>
        <p class="text-zinc-200">THE ABS 3 STAR HIGH SECURITY DOOR CYLINDER DIAMOND STANDARD APPROVED</p>
        <p>
            <image-general
                :src="$config.public.images + 'abslock.jpg'"
                imageAspectRatio="aspect-[305/189]"
            ></image-general>
        </p>
        <p>
            This lock will stop easy access into your home. Do not accept other so called Anti Snap locks…we have tried
            and tested most and they do not work.
        </p>
        <p>
            The Real Lock Company was originally setup by two partners and close friends, Stephen Allen and Darren
            Whitehead. Although, since November 2014, I Stephen Allen have taken over full control of the company due to
            Darren taking on a new venture as co-owner of Meadow Court Care Home at Hill Top, Slaithwaite.
        </p>
        <p>
            The Real Lock Company is a member of the Age UK Business Directory where certain criteria has to be met,
            including:
        </p>
        <ul>
            <li>CRB checked staff.</li>
            <li>Upfront pricing.</li>
            <li>Fully insured.</li>
            <li>Guaranteed work.</li>
            <li>An ethical code of conduct.</li>
            <li>
                And a platform where you can read and leave reviews of previous work – this can be reached by clicking on the
                <a
                    href="https://www.aubdcalderdaleandkirklees.co.uk/Result/88d0bbca-dbee-4b8e-a967-cceacca7dfba/The+Real+Lock+Company"
                    target="_blank" rel="noopener noreferrer nofollow"
                >
                    Age UK Business Directory
                </a>.
            </li>
        </ul>
        <p>
            We have a pricing structure that means it costs only a little more than doing it yourself.
        </p>
        <p>
            We offer a fast, professional and friendly service by approved ABS fitters with all work being guaranteed,
            and unlike many of our competitors we carry the stock necessary for any lock size so that we can change your
            locks there and then.
        </p>
        <content-subheading heading="Installation Prices" marginBottom="mb-6"></content-subheading>
        <p>
            All our prices include a free measuring service and fitting at your convenience. There are NO hidden
            costs:
        </p>
        <ul>
            <li>1 x ABS Lock – £75</li>
            <li>2 x ABS Locks – £130</li>
            <li>3 x ABS Locks – £180</li>
            <li>4 x ABS Locks – £230</li>
        </ul>
        <p>Each lock is supplied with three keys, extra keys £12.</p>
        <p>
            <strong>
                We will deliver a fast, professional and friendly service at the best possible rates. For more information, check out our
                <a href="/prices" class="" rel="noopener noreferrer nofollow">complete price list</a>,
                or <a href="/contact" class="" rel="noopener noreferrer nofollow">contact us now</a> to book an appointment.
            </strong>
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticleHomeTextLeft',
};
</script>
