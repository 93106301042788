<template>
    <div>
        <div>
            <content-subheading heading="TELEPHONE" marginBottom="mb-6"></content-subheading>
            <p>
                Call The Real Lock Company today about changing your locks for a cylinder lock that is truly snap secure,
                anti bump and anti pick.
            </p>
            <p><strong>{{ $config.public.companyTelephone }}</strong></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ArticleContactTextLeft',
};
</script>
