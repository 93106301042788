<template>
    <div>
        <content-subheading heading="MAILING ADDRESS" marginBottom="mb-6"></content-subheading>
        <p>
            Stephen Allen<br>
            The Real Lock Company<br>
            4 Taylor Street<br>
            Golcar<br>
            Huddersfield<br>
            HD7 4BR
        </p>
    </div>
</template>

<script>

export default {
    name: 'ArticleContactTextRight',
};
</script>
