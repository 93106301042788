<template>
    <div class="article-home">
        <p>
            {{ $config.public.siteStrapline }}
        </p>
        <ul>
            <li class="mb-2">Serverless rendering</li>
            <li class="mb-2">Low maintenance requirements</li>
            <li class="mb-2">Search engine ready</li>
            <li class="mb-2">Scales to serve any traffic</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ArticleHome',
};
</script>
