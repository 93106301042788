<template>
    <div class="section-text" :class="color">
        <layout-contained :class="padding">
            <content-heading
                v-if="heading"
                :heading="heading"
                :headingColor="headingColor"
                :headingComponent="headingComponent"
                :marginBottom="content ? 'mb-6' : 'mb-0'"
                :textCentered="textCentered"
            ></content-heading>
            <content-text
                v-if="content"
                :html="content"
                :contentColor="contentColor"
                :textCentered="textCentered"
            ></content-text>
            <div v-if="buttonText" class="mt-8" :class="!!textCentered ? 'text-center' : ''">
                <button-primary
                    :buttonColor="buttonColor || undefined"
                    :buttonRoute="buttonRoute"
                    :buttonText="buttonText"
                    :buttonClick="buttonClick"
                    :buttonClickParams="buttonClickParams"
                ></button-primary>
            </div>
            <slot name="custom"></slot>
        </layout-contained>
    </div>
</template>

<script>
export default {
    name: 'SectionText',
    props: {
        padding: {
            required: false,
            default: 'py-12 sm:py-14',
            type: String,
        },
        buttonColor: {
            required: false,
            type: String,
            default: null,
        },
        buttonRoute: {
            required: false,
            type: [String, Object],
            default: null,
        },
        buttonText: {
            required: false,
            type: String,
            default: null,
        },
        buttonClick: {
            required: false,
            type: Function,
            default: () => {},
        },
        buttonClickParams: {
            required: false,
            type: [String, Object, Array],
            default: null,
        },
        color: {
            required: false,
            type: String,
            default: 'bg-zinc-800',
        },
        content: {
            required: false,
            type: String,
            default: null,
        },
        contentColor: {
            required: false,
            type: String,
            default: 'text-zinc-400',
        },
        heading: {
            required: false,
            type: String,
            default: null,
        },
        headingColor: {
            required: false,
            type: String,
            default: 'text-yellow-400',
        },
        headingComponent: {
            required: false,
            type: String,
            default: 'h1',
        },
        textCentered: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
    },
};
</script>
