<template>
    <div>
        <p>
            We don’t charge expensive call out fees, we offer a free measuring service and we have the stocks of locks
            to fit you there and then.
        </p>
        <p>
            "All our prices include cost for supply and fitting by our approved installers at your
            convenience."
        </p>
        <p>
            Here is a detailed breakdown of our prices, which simply cannot be beaten.
        </p>
    </div>
</template>

<script>

export default {
    name: 'ArticlePricesTextRight',
};
</script>
