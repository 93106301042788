<template>
    <div>
        <content-subheading heading="EACH LOCK IS SUPPLIED WITH 3 KEYS. ALL WORK GUARANTEED." marginBottom="mb-6"></content-subheading>
        <p><strong>Extra Key - £12.00</strong></p>
        <p>
            We also offer a 'Keyed Alike' option. This is where the same key fits all your ABS Locks.
            Please <a href="/contact/" class="" rel="noopener noreferrer nofollow">contact us</a>
            for details.
        </p>
    </div>
</template>

<script>

export default {
    name: 'ArticlePricesFootnote',
};
</script>
