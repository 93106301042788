<template>
    <div>
        <p class="text-zinc-200">
            EURO STYLE LOCK CYLINDERS EXPLOITED IN LESS THAN 20 SECONDS
        </p>
        <p>
            Your home could be at serious risk. A Burglar could enter your house in under 20 seconds. We will supply
            and fit you a cylinder lock that is truly snap-secure, anti-bump and anti-pick, at the best possible
            rates.
        </p>
        <content-subheading :heading="'CALL ' + $config.public.companyTelephone"></content-subheading>
    </div>
</template>

<script>
export default {
    name: 'ArticleHomeHeadline',
};
</script>
