<template>
    <div>
        <p>
            According to West Yorkshire Police, "We have now reached the point that over 20% of House Burglaries
            within the Kirklees Division have been committed by the offenders snapping the Europrofile Locking Barrels
            on UPVC Doors."
        </p>
        <p>
            Given that there is a simple solution to this problem, this statistic is truly shocking. The number of
            unprotected houses is massive and this figure is only set to rise.
        </p>
        <p>
            At {{ $config.public.siteName }}, we have made it our mission to replace every insecure euro style door lock in
            the UK, starting with West Yorkshire, where the problem is epidemic.
        </p>
    </div>
</template>

<script>
export default {
    name: 'ArticlePricesTextLeft',
};
</script>
