<script>
import {h} from 'vue';
import ArticleContact from '@/components/article/ArticleContact.vue';
import ArticleContactTextLeft from '@/components/article/ArticleContactTextLeft.vue';
import ArticleContactTextRight from '@/components/article/ArticleContactTextRight.vue';
import ArticleHome from '@/components/article/ArticleHome.vue';
import ArticleHomeHeadline from '@/components/article/ArticleHomeHeadline.vue';
import ArticleHomeTextLeft from '@/components/article/ArticleHomeTextLeft.vue';
import ArticleHomeTextRight from '@/components/article/ArticleHomeTextRight.vue';
import ArticlePricesFootnote from '@/components/article/ArticlePricesFootnote.vue';
import ArticlePricesTextLeft from '@/components/article/ArticlePricesTextLeft.vue';
import ArticlePricesTextRight from '@/components/article/ArticlePricesTextRight.vue';
import ArticleTerms from '@/components/article/ArticleTerms.vue';

export default {
    // https://stackoverflow.com/questions/70957780/how-i-can-render-a-component-from-a-string-with-vue-3
    props: {
        html: {
            type: String,
            default: '',
        },
    },
    render() {
        const r = {
            components: {
                ArticleContact,
                ArticleContactTextLeft,
                ArticleContactTextRight,
                ArticleHome,
                ArticleHomeHeadline,
                ArticleHomeTextLeft,
                ArticleHomeTextRight,
                ArticlePricesFootnote,
                ArticlePricesTextLeft,
                ArticlePricesTextRight,
                ArticleTerms,
            },
            template: `
                <div class="content-compiled">${this.html || ''}</div>`,
            /**
            methods: {
                hello () {
                    // method "hello" is also available here
                },
            },
            **/
        };
        return h(r);
    },
};
</script>
