<template>
    <div class="embed-youtube bg-black">
        <iframe
            v-if="clicked"
            :id="'youtube-embed-' + id"
            class="w-full aspect-[16/9]"
            :src="`https://www.youtube-nocookie.com/embed/${id}?autoplay=1`"
            allowfullscreen="allowfullscreen"
            allow="autoplay"
            loading="eager"
        ></iframe>
        <embed-locked
            v-else
            class="w-full aspect-[16/9] cursor-pointer"
            :poster="`https://img.youtube.com/vi/${id}/mqdefault.jpg`"
            :onClick="onClick"
        ></embed-locked>
    </div>
</template>

<script setup>
const props = defineProps({
    id: {
        required: true,
        type: String,
        default: null,
    },
});

const clicked = ref(false);

const onClick = () => {
    clicked.value = true;
};
</script>
